import React from "react";

const TechGoodFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Congratulations!</h3>
            <p>
                It looks like you have all of the required technical equipment for Ball State University’s online
                programs.
            </p>
            <p>
                If a technology-related issue arises, however, you can always&nbsp;
                <a
                    href="https://www.bsu.edu/about/administrativeoffices/uts/utsservices/technoloy-helpdesk"
                    target="_blank"
                >
                    reach out to the HelpDesk
                </a>
                &nbsp;with your questions.
            </p>
        </div>
    );
};

export default TechGoodFeedback;
