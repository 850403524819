import React, {useState} from "react";
import WhiteCheck from "../icons/white-check.svg";
import "../styles/response.scss";

type ResponseProps = {
    onClickResponse: (response: number) => void;
    onAnimationEnd: () => void;
    chosen: number;
};

const Response = ({onClickResponse, onAnimationEnd, chosen}: ResponseProps) => {
    const [selected, setSelected] = useState("");

    return (
        <ul className="response-area">
            <li>
                <button
                    className={selected == "strongly_agree" ? "selected" : chosen == 3 ? "chosen" : ""}
                    onAnimationEnd={() => {
                        onAnimationEnd();
                        setSelected("");
                    }}
                    onClick={() => {
                        onClickResponse(3);
                        setSelected("strongly_agree");
                    }}
                    disabled={selected != ""}
                >
                    <img src={WhiteCheck} alt="white-check" />
                    Strongly Agree
                </button>
            </li>
            <li>
                <button
                    className={selected == "agree" ? "selected" : chosen == 2 ? "chosen" : ""}
                    onAnimationEnd={() => {
                        onAnimationEnd();
                        setSelected("");
                    }}
                    onClick={() => {
                        onClickResponse(2);
                        setSelected("agree");
                    }}
                    disabled={selected != ""}
                >
                    <img src={WhiteCheck} alt="white-check" />
                    Agree
                </button>
            </li>
            <li>
                <button
                    className={selected == "disagree" ? "selected" : chosen == 1 ? "chosen" : ""}
                    onAnimationEnd={() => {
                        onAnimationEnd();
                        setSelected("");
                    }}
                    onClick={() => {
                        onClickResponse(1);
                        setSelected("disagree");
                    }}
                    disabled={selected != ""}
                >
                    <img src={WhiteCheck} alt="white-check" />
                    Disagree
                </button>
            </li>
            <li>
                <button
                    className={selected == "strongly_disagree" ? "selected" : chosen == 0 ? "chosen" : ""}
                    onAnimationEnd={() => {
                        onAnimationEnd();
                        setSelected("");
                    }}
                    onClick={() => {
                        onClickResponse(0);
                        setSelected("strongly_disagree");
                    }}
                    disabled={selected != ""}
                >
                    <img src={WhiteCheck} alt="white-check" />
                    Strongly Disagree
                </button>
            </li>
        </ul>
    );
};

export default Response;
