import React from "react";
import {Link} from "react-router-dom";
import "../styles/home.scss";

const Home = () => {
    return (
        <main className="home">
            <h1>Test Your Readiness for Online Courses</h1>
            <div className={"text"}>
                <p>This quick quiz will show you just how ready you are to begin with Ball State Online</p>
                <p>
                    Ball State Online students have found the most success with their educational journey when they
                    have:
                </p>
                <ul>
                    <li>Basic computer and technology skills</li>
                    <li>Communication skills</li>
                    <li>Study skills and research strategies</li>
                    <li>Time management skills</li>
                </ul>
            </div>
            <Link to={"/useoftech"}>
                <button>CONTINUE</button>
            </Link>
        </main>
    );
};

export default Home;
