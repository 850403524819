import React from "react";
import ResultBar from "./ResultBar";
import GoodResult from "../icons/good-result.svg";
import MediumResult from "../icons/medium-result.svg";
import BadResult from "../icons/bad-result.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import CommGoodFeedback from "./feedbacksections/CommGoodFeedback";
import IdeasGoodFeedback from "./feedbacksections/IdeasGoodFeedback";
import TimeBadFeedback from "./feedbacksections/TimeBadFeedback";
import NoInternetFeedback from "./feedbacksections/NoInternetFeedback";
import NoComputerFeedback from "./feedbacksections/NoComputerFeedback";
import TechGoodFeedback from "./feedbacksections/TechGoodFeedback";
import TechBadFeedback from "./feedbacksections/TechBadFeedback";
import CommBadFeedback from "./feedbacksections/CommBadFeedback";
import IdeasBadFeedback from "./feedbacksections/IdeasBadFeedback";
import TimeGoodFeedback from "./feedbacksections/TimeGoodFeedback";

type AllResultProps = {
    handleOnClick: (section: string, image: string, feedback: React.ReactNode) => void;
};

const AllResults = ({handleOnClick}: AllResultProps) => {
    const response = JSON.parse(localStorage.getItem("results") ?? "{}");
    const resultImages = response.results.map((result: number) => {
        if (result == -1) {
            return BadResult;
        } else if (result == 0) {
            return MediumResult;
        } else {
            return GoodResult;
        }
    });

    let techFeedback;
    if (localStorage.getItem("noComputer") === "true") {
        techFeedback = <NoComputerFeedback />;
    } else if (localStorage.getItem("noInternet") === "true") {
        techFeedback = <NoInternetFeedback />;
    } else if (response.results[0] == 1) {
        techFeedback = <TechGoodFeedback />;
    } else {
        techFeedback = <TechBadFeedback />;
    }
    window.onbeforeunload = function () {
        sessionStorage.clear();
    };
    return (
        <div className="all-results">
            <h1>Results</h1>
            <p className="note">Here are your overall results. You can click through each section to learn more.</p>
            <div className="result-area">
                <ResultBar
                    section={"Use of Technology"}
                    resultImage={resultImages[0]}
                    handleClickIndividual={handleOnClick}
                    feedBack={techFeedback}
                />
                <ResultBar
                    section={"Communication with Instructor"}
                    resultImage={resultImages[1]}
                    handleClickIndividual={handleOnClick}
                    feedBack={response.results[1] == 1 ? <CommGoodFeedback /> : <CommBadFeedback />}
                />
                <ResultBar
                    section={"Expressing Ideas"}
                    resultImage={resultImages[2]}
                    handleClickIndividual={handleOnClick}
                    feedBack={response.results[2] == 1 ? <IdeasGoodFeedback /> : <IdeasBadFeedback />}
                />
                <ResultBar
                    section={"Time Management"}
                    resultImage={resultImages[3]}
                    handleClickIndividual={handleOnClick}
                    feedBack={response.results[3] == 1 ? <TimeGoodFeedback /> : <TimeBadFeedback />}
                />
            </div>
            <a href="https://www.bsu.edu/academics/collegesanddepartments/online" target="_blank">
                <button className="info-button">
                    <FontAwesomeIcon icon={faCircleInfo} className="info-circle" />
                    MORE INFO
                </button>
            </a>
            <p className="note">Learn more about Ball State Online and other online learning resources.</p>
        </div>
    );
};

export default AllResults;
