import React from "react";
import "../styles/footer.scss";

const Footer = () => {
    return (
        <footer>
            <p>Ball State Online</p>
            <p>800-872-0369</p>
            <p>765-285-1581</p>
            <p>online@bsu.edu</p>
        </footer>
    );
};

export default Footer;
