import React, {useState} from "react";
import "../styles/enrollmentstatus.scss";
import {useNavigate} from "react-router-dom";

const EnrollmentStatus = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState("");

    function handleOnClick(response: string) {
        setSelected(response);
        sessionStorage.setItem("enrolled", response);
    }
    function onAnimationEnd() {
        navigate("/addinfo");
    }

    return (
        <main className="enrollment-status">
            <h1>Enrollment Status</h1>
            <p>
                Please select if you're currently enrolled in courses at Ball State University or if you are planning to
                enroll in courses at Ball State University.
            </p>
            <button
                onClick={() => handleOnClick("true")}
                className={
                    selected == "true" ? "selected" : sessionStorage.getItem("enrolled") == "true" ? "chosen" : ""
                }
                onAnimationEnd={onAnimationEnd}
            >
                I AM CURRENTLY ENROLLED IN COURSES
            </button>
            <button
                onClick={() => handleOnClick("false")}
                className={
                    selected == "false" ? "selected" : sessionStorage.getItem("enrolled") == "false" ? "chosen" : ""
                }
                onAnimationEnd={onAnimationEnd}
            >
                I AM PLANNING TO ENROLL IN COURSES
            </button>
        </main>
    );
};

export default EnrollmentStatus;
