export interface Section {
    description: string;
}

export interface Sections {
    [name: string]: Section;
}

const sections: Sections = {
    useOfTechnology: {
        description: `
        The next five questions will help determine how ready you are with the required equipment needed to be successful in an 
        online program.
        `,
    },
    communicationWithInstructor: {
        description: `
        The next five questions will help determine how ready you are to effectively interact with your instructor in an online
        program.
        `,
    },
    expressingIdeas: {
        description: `
        The next four questions will help determine how ready you are to express your ideas in writing, which will help you in an
        online program.
        `,
    },
    timeManagement: {
        description: `
        The next four questions will help determine how ready you are to effectively manage your time, which will 
        help you in an online program.
        `,
    },
};

export default sections;
