import React from "react";
import MultistepForm from "../components/MultistepForm";
import CommIcon from "../icons/comm-icon.svg";
import CommTile from "../backgrounds/comm-tile.svg";

type CommunicationWithInstructorProps = {
    questions: string[];
};

const CommunicationWithInstructor = ({questions}: CommunicationWithInstructorProps) => {
    return (
        <>
            <MultistepForm
                totalQuestions={5}
                sectionTitle={"Communication With Instructor"}
                nextSection="expressingideas"
                image={CommIcon}
                backgroundImage={CommTile}
                questions={questions}
                sectionNumber={2}
            />
        </>
    );
};

export default CommunicationWithInstructor;
