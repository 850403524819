import React from "react";
import "../styles/progressbar.scss";

type ProgressBarProps = {
    max: number;
    current: number;
    answered: boolean;
};

const ProgressBar = ({max, current, answered}: ProgressBarProps) => {
    const circles = [];
    for (let i = 0; i < max; i++) {
        let additionalClassNames = "";
        if (i == current - 1) {
            additionalClassNames += " current";
        } else if (i < current - 1) {
            additionalClassNames += " answered";
        }
        if (i == current - 1 && answered) {
            additionalClassNames += " answered";
        }

        circles.push(
            <div className={"progress-bar-circle-container"} key={Math.random()}>
                <div className={"progress-bar-circle" + additionalClassNames} />
            </div>
        );
    }

    return <div className={"progress-bar"}>{circles}</div>;
};

export default ProgressBar;
