import React from "react";
import IndividualResult from "../components/IndividualResult";
import GoodResult from "../icons/good-result.svg";
import MediumResult from "../icons/medium-result.svg";
import BadResult from "../icons/bad-result.svg";
import TechGoodFeedback from "../components/feedbacksections/TechGoodFeedback";
import CommGoodFeedback from "../components/feedbacksections/CommGoodFeedback";
import IdeasBadFeedback from "../components/feedbacksections/IdeasBadFeedback";
import TimeBadFeedback from "../components/feedbacksections/TimeBadFeedback";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import "../styles/resultsdesktop.scss";
import TimeGoodFeedback from "../components/feedbacksections/TimeGoodFeedback";
import CommBadFeedback from "../components/feedbacksections/CommBadFeedback";
import TechBadFeedback from "../components/feedbacksections/TechBadFeedback";
import IdeasGoodFeedback from "../components/feedbacksections/IdeasGoodFeedback";
import NoComputerFeedback from "../components/feedbacksections/NoComputerFeedback";
import NoInternetFeedback from "../components/feedbacksections/NoInternetFeedback";

const ResultsDesktop = () => {
    const response = JSON.parse(localStorage.getItem("results") ?? "{}");
    const resultImages = response.results.map((result: number) => {
        if (result == -1) {
            return BadResult;
        } else if (result == 0) {
            return MediumResult;
        } else {
            return GoodResult;
        }
    });

    let techFeedback;
    if (localStorage.getItem("noComputer") === "true") {
        techFeedback = <NoComputerFeedback />;
    } else if (localStorage.getItem("noInternet") === "true") {
        techFeedback = <NoInternetFeedback />;
    } else if (response.results[0] == 1) {
        techFeedback = <TechGoodFeedback />;
    } else {
        techFeedback = <TechBadFeedback />;
    }
    window.onbeforeunload = function () {
        sessionStorage.clear();
    };
    return (
        <main className="results-desktop">
            <h1 className="main-header">Results</h1>
            <div className="feedback-container">
                <IndividualResult
                    section={"Use of Technology"}
                    resultImage={resultImages[0]}
                    feedback={techFeedback}
                    isMobile={false}
                />
                <IndividualResult
                    section={"Communication with Instructor"}
                    resultImage={resultImages[1]}
                    feedback={response.results[1] == 1 ? <CommGoodFeedback /> : <CommBadFeedback />}
                    isMobile={false}
                />
                <IndividualResult
                    section={"Expressing Ideas"}
                    resultImage={resultImages[2]}
                    feedback={response.results[2] == 1 ? <IdeasGoodFeedback /> : <IdeasBadFeedback />}
                    isMobile={false}
                />
                <IndividualResult
                    section={"Time Management"}
                    resultImage={resultImages[3]}
                    feedback={response.results[3] == 1 ? <TimeGoodFeedback /> : <TimeBadFeedback />}
                    isMobile={false}
                />
            </div>
            <a href="https://www.bsu.edu/academics/collegesanddepartments/online" target="_blank">
                <button className="info-button">
                    <FontAwesomeIcon icon={faCircleInfo} className="info-circle" />
                    MORE INFO
                </button>
            </a>
            <p className="results-desktop-note">
                Learn more about Ball State Online and other online learning resources.
            </p>
        </main>
    );
};

export default ResultsDesktop;
