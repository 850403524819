import React from "react";

const IdeasBadFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Before you move on...</h3>
            <p>
                Your “Communication of Ideas” score indicates that you may find it a little difficult to express your
                thoughts in writing.
            </p>
            <p>
                Not to worry! Ball State University’s&nbsp;
                <a href="https://www.bsu.edu/academics/centersandinstitutes/writingcenter" target="_blank">
                    Writing Center
                </a>
                &nbsp;has numerous tools, tips, and resources that will help you improve the quality of your writing.
                All of which can be accessed online.
            </p>
        </div>
    );
};

export default IdeasBadFeedback;
