import React, {useState} from "react";
import IndividualResult from "../components/IndividualResult";
import AllResults from "../components/AllResults";
import "../styles/results.scss";

const Results = () => {
    const [viewingIndividual, setViewingIndividual] = useState(false);
    const [individualResult, setIndividual] = useState(<></>);

    function handleOnClickIndividual(section: string, image: string, feedback: React.ReactNode) {
        setIndividual(
            <IndividualResult
                section={section}
                resultImage={image}
                onClickGoBack={handleOnClickGoBack}
                feedback={feedback}
                isMobile={true}
            />
        );
        setViewingIndividual(true);
    }

    function handleOnClickGoBack() {
        setIndividual(<></>);
        setViewingIndividual(false);
    }

    return (
        <main className="results-home">
            {viewingIndividual ? individualResult : <AllResults handleOnClick={handleOnClickIndividual} />}
        </main>
    );
};

export default Results;
