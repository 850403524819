import React from "react";
import "../styles/header.scss";
import beneficence from "../icons/bsu-online.png";

const Header = () => {
    return (
        <header>
            <a href="https://bsu.edu/online" target="_blank">
                <img src={beneficence} alt="BSU beneficence" />
            </a>
        </header>
    );
};

export default Header;
