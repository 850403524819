import React from "react";

const NoComputerBadFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Before you move on...</h3>
            <p>
                Access to a computer is required for online courses. Without a computer, you will not be able to access
                coursework or communicate with peers and professors.
            </p>
            <p>
                While we understand some of our learners will be using public computers, we recommend having a secure
                personal computer that you can easily access throughout the day.
            </p>
        </div>
    );
};

export default NoComputerBadFeedback;
