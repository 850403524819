import React from "react";
import MultistepForm from "../components/MultistepForm";
import BulbIcon from "../icons/bulb-icon.svg";
import BulbTile from "../backgrounds/bulb-tile.svg";

type ExpressingIdeasProps = {
    questions: string[];
};

const ExpressingIdeas = ({questions}: ExpressingIdeasProps) => {
    return (
        <>
            <MultistepForm
                totalQuestions={4}
                sectionTitle="Expressing Ideas"
                nextSection="timemanagement"
                image={BulbIcon}
                backgroundImage={BulbTile}
                questions={questions}
                sectionNumber={3}
            />
        </>
    );
};

export default ExpressingIdeas;
