import React from "react";
import MultistepForm from "../components/MultistepForm";
import WifiIcon from "../icons/wifi-icon.svg";
import WifiTile from "../backgrounds/wifi-tile.svg";

type UseOfTechnologyProps = {
    questions: string[];
};

const UseOfTechnology = ({questions}: UseOfTechnologyProps) => {
    return (
        <>
            <MultistepForm
                totalQuestions={5}
                sectionTitle="Use of Technology"
                nextSection="communication"
                image={WifiIcon}
                backgroundImage={WifiTile}
                questions={questions}
                sectionNumber={1}
            />
        </>
    );
};

export default UseOfTechnology;
