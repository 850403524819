import React from "react";

const CommGoodFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Congratulations!</h3>
            <p>
                Congratulations! It looks like you are confident and ready to effectively communicate with an online
                instructor.
            </p>
            <p>
                If you ever need help in the future, you can always reach out to your advisor for advice on
                communicating effectively with others online.
            </p>
        </div>
    );
};

export default CommGoodFeedback;
