import React from "react";

const CommBadFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Before you move on...</h3>
            <p>
                Your “Communication with Instructor” score indicates that you may find it a little difficult to
                effectively communicate with an online instructor.
            </p>
            <p>
                Not to worry! We have several tips to help you effectively communicate with others online, such as being
                aware of your tone, communicating early and often, avoiding “text speak,” and communicating through your
                Ball State email for all matters relating to Ball State.
            </p>
        </div>
    );
};

export default CommBadFeedback;
