import React, {useState} from "react";
import SectionIntro from "./SectionIntro";
import Response from "./Response";
import {useNavigate} from "react-router-dom";
import ProgressBar from "./ProgressBar";
import "../styles/multistepform.scss";

type MultistepFormProps = {
    totalQuestions: number;
    sectionTitle: string;
    nextSection?: string;
    image: string;
    backgroundImage: string;
    questions: string[];
    sectionNumber: number;
};

const MultistepForm = ({
    totalQuestions,
    sectionTitle,
    nextSection,
    image,
    backgroundImage,
    questions,
    sectionNumber,
}: MultistepFormProps) => {
    const [step, setStep] = useState(0);
    const [answered, setAnswered] = useState(false);
    const navigate = useNavigate();

    function handleClickContinue() {
        if (step == totalQuestions) {
            return;
        }
        setStep((step) => step + 1);
    }

    function handleClickResponse(response: number) {
        if (sectionTitle === "Use of Technology") {
            if (step === 1 && response === 0) {
                localStorage.setItem("noComputer", "true");
            } else if (step === 4 && response === 0) {
                localStorage.setItem("noInternet", "true");
            }
        }
        const currentAnswers = JSON.parse(sessionStorage.getItem(sectionTitle + " answers") ?? "[]");
        currentAnswers[step - 1] = response;
        sessionStorage.setItem(sectionTitle + " answers", JSON.stringify(currentAnswers));
        setAnswered(true);
    }

    function onResponseAnimationEnd() {
        if (step == totalQuestions) {
            navigate("/" + nextSection);
        }
        setAnswered(false);
        setStep((step) => step + 1);
    }

    return (
        <div className="form-content">
            {step == 0 && (
                <SectionIntro
                    onClickStart={handleClickContinue}
                    sectionTitle={sectionTitle}
                    backgroundImage={backgroundImage}
                />
            )}
            {step > 0 && (
                <main className="form-question-response">
                    <div className="question-area">
                        <img src={image} alt="section icon" className="logo" />
                        <h1>{sectionTitle}</h1>
                        <p className="section-indicator">Section {sectionNumber} out of 4</p>
                        <p>
                            Question {step} out of {totalQuestions}:
                        </p>
                        <ProgressBar max={totalQuestions} current={step} answered={answered} />
                        <p className="question">{questions[step - 1]}</p>
                    </div>

                    <Response
                        onClickResponse={handleClickResponse}
                        onAnimationEnd={onResponseAnimationEnd}
                        chosen={JSON.parse(sessionStorage.getItem(sectionTitle + " answers") ?? "[]")[step - 1]}
                    />

                    <button onClick={() => setStep(step - 1)} className="back-button">
                        BACK
                    </button>
                    {step == totalQuestions && (
                        <p className="note">
                            Note: you're about to finish this section and you wont be able to go back
                        </p>
                    )}
                </main>
            )}
        </div>
    );
};

export default MultistepForm;
