import React, {useEffect} from "react";
import UseOfTechnology from "./pages/UseOfTechnology";
import CommunicationWithInstructor from "./pages/CommunicationWithInstructor";
import ExpressingIdeas from "./pages/ExpressingIdeas";
import TimeManagement from "./pages/TimeManagement";
import AddInformation from "./pages/AddInformation";
import Home from "./pages/Home";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import EnrollmentStatus from "./pages/EnrollmentStatus";
import Results from "./pages/Results";
import ResultsDesktop from "./pages/ResultsDesktop";
import {isMobile} from "react-device-detect";

function App() {
    const techQuestions: string[] = [];
    const communicationQuestions: string[] = [];
    const ideaQuestions: string[] = [];
    const timeQuestions: string[] = [];
    async function getQuestions() {
        const questions = await fetch("https://readiness-api.apsoprojects.org/questions").then((response) =>
            response.json()
        );
        for (let i = 0; i < questions.length; i++) {
            if (i < 5) {
                techQuestions.push(questions[i].text);
            } else if (i < 10) {
                communicationQuestions.push(questions[i].text);
            } else if (i < 14) {
                ideaQuestions.push(questions[i].text);
            } else {
                timeQuestions.push(questions[i].text);
            }
        }
    }
    useEffect(() => {
        getQuestions();
    }, []);
    return (
        <>
            <Router>
                <div className="content">
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/useoftech" element={<UseOfTechnology questions={techQuestions} />} />
                        <Route
                            path="/communication"
                            element={<CommunicationWithInstructor questions={communicationQuestions} />}
                        />
                        <Route path="/expressingideas" element={<ExpressingIdeas questions={ideaQuestions} />} />
                        <Route path="/timemanagement" element={<TimeManagement questions={timeQuestions} />} />
                        <Route path="/addinfo" element={<AddInformation />} />
                        <Route path="/enrollment-status" element={<EnrollmentStatus />} />
                        <Route path="/results" element={isMobile ? <Results /> : <ResultsDesktop />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </>
    );
}

export default App;
