import React from "react";
import MultistepForm from "../components/MultistepForm";
import ClockIcon from "../icons/clock-icon.svg";
import ClockTile from "../backgrounds/clock-tile.svg";

type TimeManagementProps = {
    questions: string[];
};

const TimeManagement = ({questions}: TimeManagementProps) => {
    return (
        <>
            <MultistepForm
                totalQuestions={4}
                sectionTitle="Time Management"
                nextSection="enrollment-status"
                image={ClockIcon}
                backgroundImage={ClockTile}
                questions={questions}
                sectionNumber={4}
            />
        </>
    );
};

export default TimeManagement;
