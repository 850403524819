import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "../styles/addinformation.scss";

const AddInformation = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [haveContact, setHaveContact] = useState(false);
    const [emailResults, setEmailResults] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    async function handleOnSubmit() {
        setSubmitDisabled(true);
        const techAnswers = JSON.parse(sessionStorage.getItem("Use of Technology answers") ?? "");
        const commAnswers = JSON.parse(sessionStorage.getItem("Communication With Instructor answers") ?? "");
        const ideasAnswers = JSON.parse(sessionStorage.getItem("Expressing Ideas answers") ?? "");
        const timeAnswers = JSON.parse(sessionStorage.getItem("Time Management answers") ?? "");
        const allAnswers = techAnswers.concat(commAnswers, ideasAnswers, timeAnswers);

        const response = {
            user: {
                email: email,
                firstName: firstName,
                lastName: lastName,
                consentedContact: haveContact,
                consentedEmail: emailResults,
                enrolled: sessionStorage.getItem("enrolled") === "true",
            },
            answers: allAnswers.map((answer: number, index: number) => {
                return {
                    questionId: index + 1,
                    answerId: answer,
                };
            }),
        };
        const url = "https://readiness-api.apsoprojects.org/submitted_answers/submit";
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(response),
        })
            .then((data) => data.json())
            .then((data) => {
                localStorage.setItem("results", JSON.stringify(data));
                navigate("/results");
            })
            .catch(() => handleSubmissionError());
    }

    function handleSubmissionError() {
        alert(
            "An error occurred during submission. Please verify that all information is correct or try refreshing " +
                "the page. If you still cannot submit you may have to retake the quiz."
        );
        setSubmitDisabled(false);
    }

    return (
        <main className="add-information">
            <h1>Add Your Information</h1>
            <p>
                Please add your full name and best email to contact you. If you would like to have a Ball State
                Representative contact you to talk about your survey results, check the box below. Then Click "Submit"
                to view your results on the next screen.
            </p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleOnSubmit();
                }}
            >
                <label>
                    First Name:
                    <input
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                        required={true}
                        className="text-input"
                    />
                </label>
                <label className="text-input">
                    Last Name:
                    <input
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                        required={true}
                        className="text-input"
                    />
                </label>
                <label className="text-input">
                    Email:
                    <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                        className="text-input"
                    />
                </label>
                <label>
                    <input type="checkbox" onChange={() => setHaveContact(true)} />
                    Have a Ball State Representative contact me
                </label>
                <label>
                    <input type="checkbox" onChange={() => setEmailResults(true)} />
                    Email me my results
                </label>
                <button type="submit" disabled={submitDisabled}>
                    SUBMIT
                </button>
            </form>
        </main>
    );
};

export default AddInformation;
