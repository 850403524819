import React from "react";

const TimeBadFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Before you move on...</h3>
            <p>
                Your “Time Management” score indicates that you may have a little difficulty managing your time while
                completing an online program.
            </p>
            <p>
                Not to worry! Ball State University provides several study&nbsp;
                <a
                    href="https://www.bsu.edu/academics/collegesanddepartments/universitycollege/learningcenter/appointment-based-tutoring/study-skills/tipsstrategies"
                    target="_blank"
                >
                    skills and strategies
                </a>
                &nbsp;to help its online students succeed.
            </p>
        </div>
    );
};

export default TimeBadFeedback;
