import React from "react";

const IdeasGoodFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Congratulations!</h3>
            <p>
                It looks like you are confident in your writing abilities, which is definitely a big help in an online
                program.
            </p>
            <p>
                If you ever need help or would like additional writing tips, visit&nbsp;
                <a href="https://www.bsu.edu/academics/centersandinstitutes/writingcenter" target="_blank">
                    Ball State University’s Writing Center.
                </a>
            </p>
        </div>
    );
};

export default IdeasGoodFeedback;
