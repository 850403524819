import React from "react";

const TechBadFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Before you move on...</h3>
            <p>
                Your “Use of Technology” score indicates that you may not have all of the required equipment to for Ball
                State University’s online programs.
            </p>
            <p>
                Not to worry! You can review the&nbsp;
                <a href="https://www.bsu.edu/about/administrativeoffices/technology-store/students" target="_blank">
                    full list of technology
                </a>
                &nbsp;needs and make sure you are ready before the semester begins
            </p>
        </div>
    );
};

export default TechBadFeedback;
