import React from "react";

type IndividualResultsProps = {
    section: string;
    resultImage: string;
    onClickGoBack?: () => void;
    feedback: React.ReactNode;
    isMobile: boolean;
};

const IndividualResult = ({section, resultImage, onClickGoBack, feedback, isMobile}: IndividualResultsProps) => {
    return (
        <div className="individual-results">
            <h1>{section}</h1>
            <img src={resultImage} alt="Result Bar" />
            {feedback}
            {isMobile && (
                <button className="back-button" onClick={onClickGoBack}>
                    BACK TO RESULTS
                </button>
            )}
        </div>
    );
};

export default IndividualResult;
