import React from "react";
import sections from "../Sections";
import "../styles/sectionintro.scss";

type SectionIntroProps = {
    onClickStart: () => void;
    sectionTitle: string;
    backgroundImage: string;
};

function formatTitle(title: string) {
    const stringArray = title.split(" ");
    for (let i = 0; i < stringArray.length; i++) {
        const word = stringArray[i];
        if (i == 0) {
            stringArray[i] = word.charAt(0).toLowerCase() + word.slice(1);
        } else {
            stringArray[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
    }
    return stringArray.join("");
}

const SectionIntro = ({onClickStart, sectionTitle, backgroundImage}: SectionIntroProps) => {
    const mediaMatch = window.matchMedia("(max-width: 500px)");
    let backgroundStyle = {};
    if (mediaMatch.matches) {
        backgroundStyle = {
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
        };
    }

    return (
        <main className="section-intro" style={backgroundStyle}>
            <h1>{sectionTitle}</h1>
            <div>
                <p>{sections[formatTitle(sectionTitle)].description}</p>
                <p>Please indicate your level of agreement with the following statements.</p>
            </div>
            <button onClick={onClickStart}>CONTINUE</button>
        </main>
    );
};

export default SectionIntro;
