import React from "react";
import ArrowButton from "../icons/arrow-button.svg";
import "../styles/resultbar.scss";

type ResultBarProps = {
    section: string;
    resultImage: string;
    handleClickIndividual: (section: string, image: string, feedback: React.ReactNode) => void;
    feedBack: React.ReactNode;
};

const ResultBar = ({section, resultImage, handleClickIndividual, feedBack}: ResultBarProps) => {
    return (
        <div className="result-bar">
            <div>
                <p className="section-text">{section}</p>
                <img src={resultImage} alt={`Result Bar`} />
            </div>
            <button>
                <img
                    src={ArrowButton}
                    alt="Arrow Button"
                    onClick={() => handleClickIndividual(section, resultImage, feedBack)}
                />
            </button>
        </div>
    );
};

export default ResultBar;
