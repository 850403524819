import React from "react";

const NoInternetFeedback = () => {
    return (
        <div className="feedback-area">
            <h3>Before you move on...</h3>
            <p>
                To be a successful online student, you need high-speed internet access. You need it to participate in
                orientation, access coursework and Ball State’s academic support services, and communicate with peers
                and professors.
            </p>
        </div>
    );
};

export default NoInternetFeedback;
